import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../templates/layout/layout"
import SEO from "../templates/layout/seo"

import HeaderSlider from "../templates/sections/HeaderSlider"
import Categories from "../templates/sections/Categories"
import Products from "../templates/sections/Products"
import ProductSlider from "../templates/sections/ProductSlider"

import { Button } from "reactstrap"
let Index = ({ data }) => {
  const { slides, products, categories } = data.contentfulLandingpage
  const { nameProducts } = data.contentfulLandingpage

  return (
    <Layout>
      <SEO title="ArtGreetings" />
      <HeaderSlider slides={slides} />
      <Categories categories={categories} />
      <Products title={nameProducts} products={products} />

      <div
        className="container"
        style={{ maxWidth: "30rem", marginBottom: "5rem" }}
      >
        <Button tag={Link} to={`/category/Karten`} color="dark" block size="lg">
          Alle Karten ansehen
        </Button>
        {/* <a
          className="btn btn-dark btn-lg btn-block"
          // style={{ backgroundColor: "rgb(241, 100, 30)", color: "#fff" }}
          href="https://www.etsy.com/de/shop/ArtGreetingsShop"
          target="_blank"
        >
          Alle Karten auf Etsy ansehen
        </a> */}
      </div>
      {/* <ProductSlider className="mb-5 py-5" products={products} /> */}
    </Layout>
  )
}

export default Index

export const query = graphql`
  query indexPage {
    contentfulLandingpage(name: { eq: "Startseite" }) {
      id
      slides {
        id
        title
        description {
          description
        }
        image {
          gatsbyImageData(aspectRatio: 2, layout: FULL_WIDTH, quality: 100)
        }
      }
      nameCategories
      categories {
        id
        name
        image {
          id
          gatsbyImageData(
            formats: NO_CHANGE
            placeholder: BLURRED
            layout: FULL_WIDTH
            aspectRatio: 1.77
          )
        }
        slug
      }
      nameProducts
      products {
        id
        name
        description {
          description
        }
        price
        category {
          name
          slug
        }
        subcategory {
          name
          slug
        }
        mainImage {
          gatsbyImageData(
            formats: NO_CHANGE
            placeholder: BLURRED
            layout: CONSTRAINED
            aspectRatio: 1
          )
        }
        images {
          gatsbyImageData(
            placeholder: BLURRED
            formats: NO_CHANGE
            layout: FULL_WIDTH
          )
        }
        slug
      }
    }
  }
`

import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { CardTitle, CardImgOverlay, Button } from "reactstrap"

export default function Category(props) {
  const { image, name, slug } = props.category
  if (!image)
    return (
      <Button tag={Link} to={`/category/${slug}`} block>
        {name}
      </Button>
    )

  return (
    <CategoryCard tag="Link" to={`/category/${slug}`} className="mb-2 border-0">
      <GatsbyImage image={getImage(image)} loading="auto" alt={name} />
      <CardImgOverlay className="d-flex align-items-end">
        <div className="darken">
          <CardTitle
            tag="h4"
            className="mb-3 ml-3 font-weight-bold text-truncate"
          >
            {name}
          </CardTitle>
        </div>
      </CardImgOverlay>
    </CategoryCard>
  )
}

const CategoryCard = styled(Link)`
  color: #fff;
  :hover {
    color: #fff;
    text-decoration: none !important;
  }
  img {
    border-radius: ${({ theme }) => theme.borderRadius};
  }
  /* .darken {
    background: rgb(0, 0, 0);
    background: linear-gradient(
      6deg,
      rgba(0, 0, 0, 1) 0%,
      rgba(0, 0, 0, 0.7693671218487395) 9%,
      rgba(0, 0, 0, 0) 20%
    );
  } */
`

import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import styled from "styled-components"

import "../../../node_modules/slick-carousel/slick/slick.css"
import "../../../node_modules/slick-carousel/slick/slick-theme.css"

import { ArrowLeft, ArrowRight } from "../../components/sliderButton"

export default function HeaderSlider(props) {
  const { slides = [], settings } = props

  const defautlSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    arrows: true,
    responsive: [
      {
        breakpoint: 991,
        settings: { arrows: false },
      },
    ],
    ...settings,
  }
  if (slides.length < 1)
    return <div className="py-2 text-center text-danger">no slides</div>

  return (
    <Slider {...defautlSettings}>
      {slides.map((slide) => (
        <div key={slide.id}>
          <SliderItem>
            <GatsbyImage
              image={getImage(slide.image)}
              alt="slide.title"
              style={{ gridArea: "1/1" }}
            />
            {(slide.title || slide.description || slide.url) && (
              <div className="slider-overlay">
                <div>
                  {slide.title && <h1>{slide.title}</h1>}
                  {slide.description && <p>{slide.description.description}</p>}
                </div>
              </div>
            )}
          </SliderItem>
        </div>
      ))}
    </Slider>
  )
}

const SliderItem = styled.div`
  height: 16rem;
  @media (min-width: 576px) {
    height: 20rem;
  }
  @media (min-width: 768px) {
    height: 25rem;
  }
  @media (min-width: 992px) {
    height: 30rem;
  }
  @media (min-width: 1200px) {
    height: 35rem;
  }
  display: grid;
  color: white;
  .slider-overlay {
    grid-area: 1/1;
    position: relative;
    place-items: center;
    display: grid;
  }
`

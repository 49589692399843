import React from "react"
import { Row, Col, Container } from "reactstrap"
import Category from "../../components/category"

export default function Categories(props) {
  const { title, categories } = props

  return (
    <section className="py-5">
      <Container fluid>
        {title && <h3 className="mb-4">{title}</h3>}
        <Row>
          {categories.map((category) => (
            <Col xs="12" md="6" key={category.id} style={{ padding: "15px" }}>
              <Category key={category.id} category={category} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}
